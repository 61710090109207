import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index'),
  },
  // 任务宝
  {
    path: '/workFission',
    name: 'workFissionIndex',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/index'),
  },
  {
    path: '/workFission/speed',
    component: () => import(/* webpackChunkName: "workFission" */ '@/views/workFission/speed'),
  },
  // 抽奖活动
  {
    path: '/lottery',
    name: 'lotteryIndex',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/index'),
  },
  // 抽奖活动——规则说明
  {
    path: '/explain',
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/explain'),
  },
  // 互动雷达
  {
    path: '/radar',
    name: 'radar',
    component: () => import(/* webpackChunkName: "radar" */ '@/views/radar/index'),
  },
  // 群打卡
  {
    path: '/roomClockIn',
    name: 'roomClockIn',
    component: () => import(/* webpackChunkName: "roomClockIn" */ '@/views/roomClockIn/index'),
  },
  // 门店活码
  {
    path: '/shopCode',
    name: 'shopCodeIndex',
    component: () => import(/* webpackChunkName: "shopCode" */ '@/views/shopCode/index'),
  },
  // 群裂变
  {
    path: '/roomFission',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/index'),
  },
  // 群裂变海报
  {
    path: '/roomFission/poster',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/poster'),
  },
  // 群裂变——助力进度
  {
    path: '/roomFission/speed',
    component: () => import(/* webpackChunkName: "roomFission" */ '@/views/roomFission/speed'),
  },
  // 无限拉群
  {
    path: '/roomInfinitePull',
    component: () => import(/* webpackChunkName: "roomInfinitePull" */ '@/views/roomInfinitePull/index'),
  },
  // 引流链接
  {
    path: '/drainageLink',
    component: () => import(/* webpackChunkName: "drainageLink" */ '@/views/drainageLink/index'),
  },
  {
    path: '/exclusive',
    component: () => import(/* webpackChunkName: "exclusive" */ '@/views/exclusive/index'),
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
